import React from "react";
import { RichText } from "prismic-reactjs";
import { SwiperSlide } from "swiper/react";

import VerbatimText from "../../components/VerbatimText/VerbatimText";
import DynamicSlider from "../../components/DynamicSlider/DynamicSlider";

import "./SectionClients.scss";

function SectionClients({ componentData }) {
  return (
    <section
      style={{ backgroundColor: componentData.primary.section_color }}
      className="section-clients"
    >
      <div className="section-clients-title">
        <RichText render={componentData.primary.title} />
      </div>

      <div className="section-clients-carousel">
        <DynamicSlider nbSlidesPerView={4} sliderIdNumber={1}>
          {componentData.fields.map((client, i) => {
            return (
              <SwiperSlide
                style={{
                  marginRight: "0px",
                  width: "100px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
                key={i}
              >
                <a href={client.link.url}>
                  <VerbatimText
                    is_logo={true}
                    url={client.logo.url}
                    alt={client.logo.alt}
                  />
                </a>
              </SwiperSlide>
            );
          })}
        </DynamicSlider>
      </div>
    </section>
  );
}

export const SectionClientsQuery = graphql`
  fragment SectionClients on PRISMIC_Dynamic_page_builderBodySection_clients {
    type
    fields {
      logo
      link {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
    }
    primary {
      section_color
      title
    }
  }
`;

export default SectionClients;
