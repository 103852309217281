import React from "react";
import _ from "lodash";

import SEO from "../components/SEO";

// Import des section/slices de l'apllication
import HeroB from "../sections/HeroB/HeroB";
import HeroD from "../sections/HeroD/HeroD";
import SectionBlog from "../sections/SectionBlog/SectionBlog";
import SectionCarrousel from "../sections/SectionCarrousel/SectionCarrousel";
import SectionClients from "../sections/SectionClients/SectionClients";
import SectionCta from "../sections/SectionCta/SectionCta";
import SectionPains from "../sections/SectionPains/SectionPains";
import SectionPresentation from "../sections/SectionPresentation/SectionPresentation";
import SectionVerbatim from "../sections/SectionVerbatim/SectionVerbatim";
import SectionVideo from "../sections/SectionVideo/SectionVideo";

// Objet permettant de relier le nom converti au Composant React
const componentsMap = {
  HeroB: HeroB,
  HeroD: HeroD,
  SectionBlog: SectionBlog,
  SectionCarrousel: SectionCarrousel,
  SectionClients: SectionClients,
  SectionCta: SectionCta,
  SectionPains: SectionPains,
  SectionPresentation: SectionPresentation,
  SectionVerbatim: SectionVerbatim,
  SectionVideo: SectionVideo,
};

// Définition de la page react (format React 18|19)
function DynamicPage({ data, pageContext: { uid, locale }, location }) {
  if (!data) return null;

  // Fonction de conversion du type de slice prismic en nom de composant React
  const convertSliceToComponent = (sliceType) => {
    const componentName = _.upperFirst(_.camelCase(sliceType));
    return componentsMap[componentName] || null;
  };

  const DynamicPageData = data.prismic.dynamic_page_builder;

  return (
    <>
      <SEO
        title={DynamicPageData.meta_title}
        desc={DynamicPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        location={location}
        pathname={location.pathname}
      />

      {/* Boucle permettant d'appeler dynamiquement les éléments présent dans la page prismic */}
      {DynamicPageData.body.map((slice, index) => {
        const Component = convertSliceToComponent(slice.type);
        if (!Component) return null;
        return <Component key={index} componentData={slice} />;
      })}
    </>
  );
}

// Query graphql récupérant uniquement les types de slices présent dans la page
export const DynamicPageQuery = graphql`
  query DynamicPageQuery($uid: String!, $locale: String!) {
    prismic {
      dynamic_page_builder(lang: $locale, uid: $uid) {
        body {
          ...HeroB
          ...HeroD
          ...SectionBlog
          ...SectionCarrousel
          ...SectionClients
          ...SectionCta
          ...SectionPains
          ...SectionPresentation
          ...SectionVerbatim
          ...SectionVideo
        }
      }
    }
    site {
      ...SiteInformation
    }
  }
`;

export default DynamicPage;
