import React from "react";
import { RichText } from "prismic-reactjs";

import "./SectionBlog.scss";

function SectionBlog({ componentData }) {
  return (
    <section
      style={{ backgroundColor: componentData.primary.section_color }}
      className="section-blog"
    >
      <div className="section-blog-title">
        <RichText render={componentData.primary.title} />
      </div>

      <div className="section-blog-blogs">
        {componentData.fields.map((blog, i) => {
          return (
            <a
              key={i}
              href={`/${blog.blog_link._meta.lang.split("-")[0]}_${
                blog.blog_link._meta.lang.split("-")[1]
              }/blog/${blog.blog_link._meta.uid}`}
              className="section-blog-blogs-card"
            >
              <div className="section-blog-blogs-card-image">
                {blog.blog_image && (
                  <img src={blog.blog_image.url} alt={blog.blog_image.alt} />
                )}
              </div>
              <div className="section-blog-blogs-card-richtext">
                <RichText render={blog.blog_summary} />
              </div>
            </a>
          );
        })}
      </div>
    </section>
  );
}

export const SectionBlogQuery = graphql`
  fragment SectionBlog on PRISMIC_Dynamic_page_builderBodySection_blog {
    type
    primary {
      title
      section_color
    }
    fields {
      blog_summary
      blog_image
      blog_link {
        ... on PRISMIC_Blogpost {
          title
          _meta {
            uid
            lang
          }
        }
      }
    }
  }
`;

export default SectionBlog;
