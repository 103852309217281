import React from "react";
import { RichText } from "prismic-reactjs";

import "./ListContainer.scss";

function ListContainer({ image, text }) {
  return (
    <div className="list-container">
      <div className="list-container-logo">
        {image && <img src={image.url} alt={image.alt} />}
      </div>

      <div className="list-container-richtext">
        <RichText render={text} />
      </div>
    </div>
  );
}

export default ListContainer;
