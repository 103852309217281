import React from "react";
import { RichText } from "prismic-reactjs";

import ColorButton from "../../components/ColorButton/ColorButton";

import "./SectionPresentation.scss";

function SectionPresentation({ componentData }) {
  return (
    <section
      style={{ backgroundColor: componentData.primary.section_color }}
      className="section-presentation"
    >
      {componentData.primary.title && (
        <RichText render={componentData.primary.title} />
      )}

      {componentData.fields.map((part, i) => {
        return (
          <div
            key={i}
            style={{ backgroundColor: part.background_color }}
            className={
              part.image_right
                ? "section-presentation-part"
                : "section-presentation-part is-reverse"
            }
          >
            {part.description && (
              <div className="section-presentation-part-richtext">
                <RichText render={part.description} />
                {part.demo_btn_name && (
                  <ColorButton
                    background="linear-gradient(to left, #FD7543, #DE4E4E)"
                    variant="contained"
                    name={part.demo_btn_name}
                    link={part.demo_link ? part.demo_link.url : "#"}
                  />
                )}
              </div>
            )}

            {part.liste && (
              <div className="section-presentation-part-richtext">
                <RichText render={part.liste} />
              </div>
            )}
            {part.image ? (
              <div
                className={
                  part.image_background
                    ? `section-presentation-part-imageB
                        ${part.image_fixed ? "is-fixed" : ""}`
                    : `section-presentation-part-image
                        ${part.image_fixed ? "is-fixed" : ""}`
                }
              >
                <div
                  className={
                    part.image_background
                      ? "section-presentation-part-imageB-container"
                      : "section-presentation-part-image-container"
                  }
                >
                  <img src={part.image.url} />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </section>
  );
}

export const SectionPresentationQuery = graphql`
  fragment SectionPresentation on PRISMIC_Dynamic_page_builderBodySection_presentation {
    type
    fields {
      background_color
      description
      image
      image_right
      demo_btn_name
      demo_link {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
      liste
      image_background
      image_fixed
    }
    primary {
      section_color
      title
    }
  }
`;

export default SectionPresentation;
