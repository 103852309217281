import React, { useState, useEffect } from "react";
import { RichText } from "prismic-reactjs";

import ColorButton from "../../components/ColorButton/ColorButton";

import "./HeroD.scss";

function HeroD({ componentData }) {
  if (!componentData) {
    throw new Error("Le composant n'a pas recu de données");
  }

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );
    setIsMobile(mobile);
  }, []);

  const HeroDData = componentData.primary;

  const backgroundImageUrl = HeroDData.background_image.url.split("?")[0];

  return (
    <header
      style={
        HeroDData.is_background
          ? {
              background: `center / cover no-repeat url("${backgroundImageUrl}")`,
            }
          : { background: `white` }
      }
      className="hero-header"
    >
      <div className="hero-header-container">
        <div
          className={
            HeroDData.is_background ? "is_background_title" : "is_image_title"
          }
        >
          <RichText render={HeroDData.title} />
        </div>

        <div
          className={
            HeroDData.is_background ? "is_background_text" : "is_image_text"
          }
        >
          <RichText render={HeroDData.description} />
        </div>

        {!HeroDData.is_background && (
          <div className="hero-header-container-image">
            <img
              src="https://cdn.cloud.monstock.net/website/fr_fr/Zsx0NUaF0TcGJZLV_mobile-pro-durci-avif.png"
              alt={HeroDData.background_image.alt}
            />
          </div>
        )}

        <div className="hero-header-container-btn">
          <ColorButton
            background="linear-gradient(to left, #FD7543, #DE4E4E)"
            fontSize="1.3rem"
            link={HeroDData.demo_btn_link.url}
            name={HeroDData.demo_btn_name}
          ></ColorButton>
        </div>
      </div>
    </header>
  );
}

export const HeroDQuery = graphql`
  fragment HeroD on PRISMIC_Dynamic_page_builderBodyHero_d {
    type
    primary {
      background_image
      demo_btn_link {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
      title
      demo_btn_name
      description
      is_background
    }
  }
`;

export default HeroD;
