import React, { useState } from "react";
import { RichText } from "prismic-reactjs";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Controller, Pagination } from "swiper";

import ColorButton from "../../components/ColorButton/ColorButton";

import "./SectionCarrousel.scss";

SwiperCore.use([Navigation, Controller, Pagination]);

function SectionCarrousel({ componentData }) {
  const [featureSwiperThumbs, setFeatureSwiperThumbs] = useState(null);
  const [featureSwiper, setFeatureSwiper] = useState(null);
  const [dotSelected, setDotSelected] = useState(null);
  const [swipeActivate, setSwipeActivate] = useState(true);

  return (
    <section
      style={{ backgroundColor: componentData.primary.section_color }}
      className="section-carrousel"
    >
      <div className="section-carrousel-menu">
        <Swiper
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loopedSlides={1}
          onSwiper={setFeatureSwiperThumbs}
          slideToClickedSlide={true}
          controller={{ control: featureSwiper }}
          navigation={{
            nextEl: ".swiper-carrousel-button-next",
            prevEl: ".swiper-carrousel-button-prev",
          }}
          className="swiperClass"
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 130,
            },
            1400: {
              slidesPerView: 3,
              spaceBetween: 130,
            },
          }}
          onSlideChange={() => setTimeout}
          onSlideChangeTransitionStart={() => {
            setDotSelected(false);
            setSwipeActivate(true);
          }}
          allowSlideNext={swipeActivate}
          threshold={30}
        >
          {componentData.fields.map((carousel, i) => {
            return (
              <SwiperSlide key={i}>
                <div className="section-carrousel-menu-slide">
                  <div>
                    <img
                      src={
                        carousel.carrousel_btn_icon
                          ? carousel.carrousel_btn_icon.url
                          : ""
                      }
                      alt={
                        carousel.carrousel_btn_icon
                          ? carousel.carrousel_btn_icon.alt
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <RichText render={carousel.carrousel_btn_title} />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <div className="section-carrousel-elements">
        <Swiper
          onSwiper={setFeatureSwiper}
          loop={true}
          controller={{ control: featureSwiperThumbs }}
          loopedSlides={1}
          allowSlidePrev={swipeActivate}
          allowSlideNext={swipeActivate}
          threshold={30}
        >
          {componentData.fields.map((carousel, i) => {
            return (
              <SwiperSlide key={i}>
                <div className="section-carrousel-elements-slide">
                  <div className="section-carrousel-elements-slide-image">
                    <img
                      src={
                        carousel.carrousel_image
                          ? carousel.carrousel_image.url
                          : ""
                      }
                      alt={
                        carousel.carrousel_image
                          ? carousel.carrousel_image.alt
                          : ""
                      }
                    />
                  </div>
                  <div className="section-carrousel-elements-slide-text">
                    <RichText render={carousel.carrousel_text} />
                    <ColorButton
                      fontSize="0.9rem"
                      background="linear-gradient(to left, #FD7543, #DE4E4E)"
                      variant="contained"
                      size="small"
                      name={carousel.demo_btn_name}
                      link={
                        carousel.demo_btn_link
                          ? carousel.demo_btn_link.url
                          : "#"
                      }
                    />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
}

export const SectionCarrouselQuery = graphql`
  fragment SectionCarrousel on PRISMIC_Dynamic_page_builderBodySection_carrousel {
    type
    fields {
      carrousel_btn_icon
      carrousel_btn_title
      carrousel_image
      carrousel_text
      demo_btn_link {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
      demo_btn_name
    }
    primary {
      section_color
    }
  }
`;

export default SectionCarrousel;
