import React from "react";
import { RichText } from "prismic-reactjs";

import ColorButton from "../../components/ColorButton/ColorButton";

import "./SectionCta.scss";

function SectionCta({ componentData }) {
  const backgroundImageUrl = componentData.primary.background_image.url.split(
    "?"
  )[0];

  return (
    <section className="section-cta">
      <div
        className="section-cta-container"
        style={
          componentData.primary.image_background
            ? {
                background: `center / cover no-repeat url("${backgroundImageUrl}")`,
              }
            : {
                background: `linear-gradient(to right, ${componentData.primary.gradient_start},${componentData.primary.gradient_end})`,
              }
        }
      >
        <RichText render={componentData.primary.title} />
        <ColorButton
          fontSize="0.9rem"
          background="linear-gradient(to left, #FD7543, #DE4E4E)"
          variant="contained"
          size="small"
          name={componentData.primary.btn_name}
          link={componentData.primary.btn_link ? componentData.primary.btn_link.url : "#"}
        />
      </div>
    </section>
  );
}

export const SectionRetractableQuery = graphql`
  fragment SectionCta on PRISMIC_Dynamic_page_builderBodySection_cta {
    type
    primary {
      background_image
      btn_link {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
      btn_name
      gradient_start
      gradient_end
      image_background
      title
    }
  }
`;

export default SectionCta;
