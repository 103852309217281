import React from "react";
import { RichText } from "prismic-reactjs";

import ListContainer from "../../components/ListContainer/ListContainer";

import "./SectionPains.scss";

function SectionPains({ componentData }) {
  return (
    <section
      style={{ backgroundColor: componentData.primary.section_color }}
      className="section-pains"
    >
      <div className="section-pains-richtext">
        {componentData.primary.description && (
          <RichText render={componentData.primary.description} />
        )}

        {componentData.fields.map((part, i) => {
          return (
            <ListContainer
              key={i}
              image={part.image}
              text={part.container_text}
            />
          );
        })}
      </div>

      {componentData.primary.image && (
        <div
          className={
            componentData.primary.image_background
              ? "section-pains-imageB"
              : "section-pains-image"
          }
        >
          <img src={componentData.primary.image.url} />
        </div>
      )}
    </section>
  );
}

export const SectionPainsQuery = graphql`
  fragment SectionPains on PRISMIC_Dynamic_page_builderBodySection_pains {
    type
    fields {
      container_text
      image
    }
    primary {
      demo_btn_name
      demo_link {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
      description
      image
      image_background
      image_right
      section_color
    }
  }
`;

export default SectionPains;
