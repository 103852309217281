import React from "react";

import "./ColorButton.scss";

function ColorButton({ background, variant, link, name }) {
  return (
    <button
      href={link}
      style={{ background: background }}
      className={
        variant === "contained"
          ? "color-btn-contained"
          : variant === "outlined"
          ? "color-btn-outlined"
          : "color-btn"
      }
    >
      {name}
    </button>
  );
}

export default ColorButton;
