import React, { useState } from "react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "../../styles/swiper/swiper.scss";
import "../../styles/swiper/navigation.scss";
import "./DynamicSlider.scss"

SwiperCore.use([Navigation, Autoplay]);

function DynamicSlider({
  children,
  is_card,
  is_carrousel,
  nbSlidesPerView,
  sliderIdNumber,
  noBtn,
}) {
  let sliderId = sliderIdNumber;
  const [slidesPerView, setSlidesPerView] = useState(nbSlidesPerView);
  const [loopedSlides, setLoopedSlides] = useState(nbSlidesPerView);

  return (
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-12 awards-slider">
          <>
            <div className="columns is-centered py-6">
              <div className="">
                <div className="slider-main">
                  <Swiper
                    spaceBetween={is_card ? 500 : 0}
                    centeredSlides={true}
                    slideToClickedSlide={true}
                    autoplay
                    loop={true}
                    lazy={true}
                    loopedSlides={loopedSlides}
                    slidesPerView={slidesPerView}
                    className={is_card ? "swiper-class-card" : "swiper-class"}
                    breakpoints={{
                      300: {
                        slidesPerView: 1,
                        loopedSlides: 1,
                        spaceBetween: is_card ? 500 : 30,
                      },
                      1200: {
                        slidesPerView: is_card ? 1 : 3,
                        loopedSlides: is_card ? 1 : 3,
                        spaceBetween: is_card ? 500 : 200,
                      },
                      1500: {
                        slidesPerView: slidesPerView,
                        loopedSlides: loopedSlides,
                        spaceBetween: is_card ? 600 : 10,
                      },
                      2520: {
                        slidesPerView: slidesPerView,
                        loopedSlides: loopedSlides,
                        spaceBetween: is_card ? 500 : 10,
                      },
                    }}
                    navigation={{
                      nextEl: `.swiper-${sliderId}-next`,
                      prevEl: `.swiper-${sliderId}-prev`,
                    }}
                  >
                    <div className={is_carrousel && "is_carrousel"}>
                      {children}
                    </div>
                  </Swiper>

                  {!noBtn && (
                    <>
                      <div
                        role="button"
                        name="Previous slide"
                        aria-label="Previous slide"
                        className={`swiper-button-prev swiper-${sliderId}-prev`}
                      ></div>
                      <div
                        role="button"
                        name="Next slide"
                        aria-label="Next slide"
                        className={`swiper-button-next swiper-${sliderId}-next`}
                      ></div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default DynamicSlider;
