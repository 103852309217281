import React from "react";
import { RichText } from "prismic-reactjs";

import ColorButton from "../../components/ColorButton/ColorButton";

import "./HeroB.scss";

function HeroB({ componentData }) {
  return (
    <header style={{ backgroundColor: "#EFF3FE" }} className="herob-header">
      <div className="herob-header-text">
        <RichText render={componentData.primary.title} />
        <RichText render={componentData.primary.description} />
        <div className="herob-header-text-btns">
          <ColorButton
            background="linear-gradient(to left, #FD7543, #DE4E4E)"
            variant="contained"
            link={componentData.primary.demo_link.url}
            name={componentData.primary.demo_btn_name}
          />
          <ColorButton
            variant="outlined"
            background="none"
            link={componentData.primary.freetrial_link.url}
            name={componentData.primary.freetrial_btn_name}
          />
        </div>
      </div>
      <div className="herob-header-image">
        <img
          src={componentData.primary.image.url}
          alt={componentData.primary.image.alt}
        />
      </div>
    </header>
  );
}

export const HeroBQuery = graphql`
  fragment HeroB on PRISMIC_Dynamic_page_builderBodyHero_b {
    type
    primary {
      title
      image
      freetrial_link {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
      freetrial_btn_name
      description
      demo_link {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
      demo_btn_name
    }
  }
`;

export default HeroB;
