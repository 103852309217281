import React from "react";
import { RichText } from "prismic-reactjs";

import "./VerbatimText.scss";

function VerbatimText({
  is_logo,
  is_carrousel,
  image_above,
  text_info,
  url,
  alt,
}) {
  return (
    <div
      className={
        is_logo
          ? image_above
            ? "verbatimtext is_above is_logo"
            : "verbatimtext is_logo"
          : is_carrousel
          ? image_above
            ? "verbatimtext is_above is_carrousel"
            : "verbatimtext is_carrousel"
          : image_above
          ? "verbatimtext is_above"
          : "verbatimtext"
      }
    >
      {!is_logo && (
        <div className="verbatimtext-richtext">
          <RichText render={text_info} />
        </div>
      )}

      <div className="verbatimtext-image">
        <img src={url.split("?")[0]} alt={alt} />
      </div>
    </div>
  );
}

export default VerbatimText;
